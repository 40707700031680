import React, { useEffect, useState } from 'react';
import { getCourse, getOrders } from '../../pocketbase/pocketbase';
import { Link } from 'react-router-dom';
import { Course, Order } from "../../app/types";

const CoursePage: React.FC = () => {
  const [orders, setOrders] = useState<Order[] | null>(null); // Initialize as null to represent loading state
  const [courses, setCourses] = useState<Course[] | null>(null);

  useEffect(() => {
    let isMounted = true;

    getOrders()
      .then((res: any) => {
        // Ensure response is an array before setting state
        if (isMounted && Array.isArray(res)) {
          setOrders(res);
        } else {
          // Handle non-array responses or errors gracefully
          console.error("getOrders did not return an array", res);
          setOrders([]);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch orders:", error);
        if (isMounted) setOrders([]);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    getCourse()
      .then((res: any) => {
        if (isMounted && Array.isArray(res)) {
          setCourses(res);
        } else {
          console.error("getCourse did not return an array", res);
          setCourses([]);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch courses:", error);
        if (isMounted) setCourses([]);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="flex flex-col h-full justify-center items-center">
      <h1 className="text-3xl mb-6">Менің курстарым</h1>
      <div className="w-full container flex flex-wrap items-center justify-center gap-5 m-5">
        {courses && courses.map((course) => (
          <Link key={course.id} to={`/course/${course.id}`} className="w-full md:w-1/4">
            <div className="shadow-lg hover:shadow-xl  duration-300 ease-in-out overflow-hidden border border-[#353535] rounded-xl hover:scale-110 transition-all">
              <img
                className="w-full h-48 object-cover"
                alt="Course"
                src={`https://admin.bigtabys.com/api/files/gumx761xpuqu21h/${course.id}/${course.image}`}
              />
              <div className="p-5 bg-[#141414]">
                <h5 className="text-lg font-bold">{course.name}</h5>
                <p>{course.short}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
      {orders && orders.map((order) => (
        <div key={order.id}>
          <h2 className="text-2xl font-bold">{order.user}</h2>
          <h2 className="text-2xl font-bold">{order.id}</h2>
        </div>
      ))}
    </div>
  );
};

export {CoursePage};
