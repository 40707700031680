import style from './Header.module.css'
import {Link} from "react-router-dom";

const Header: React.FC = () => {
    return (
        <div style={{background: 'black'}}>
            <div className={style.main}>
                <div className={style.wrapper}>
                    <div className={style.logo}>
                        <Link to={'/'} style={{fontSize: 22, fontWeight: 600}}>
                        Big Tabys
                        </Link>
                        <img/>
                    </div>
                    <nav className={style.navbar}>
                        <Link to={'/'}>
                            Басты бет
                        </Link>
                        <Link to={'/course'}>
                            Жеке кабинет
                        </Link>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export {Header}
