import {Link} from "react-router-dom";

const TelegramSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telegram"
    viewBox="0 0 16 16"
  >
    <path
      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"
    />
  </svg>
)

const Footer: React.FC = () => {
  return (
    <div className="bg-[#141414] text-white py-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="space-y-4">
            <div className="font-bold text-5xl">Big Tabys</div>
            <p>Оқу - жарық, не оқу - күнсіздік!</p>
          </div>
          <div className="space-y-4">
            <div className="font-bold text-xl">Навигация</div>
            <div>
              <Link to={'/'} className="text-blue-400 hover:text-blue-500">Басты бет</Link>
            </div>
          </div>
          <div className="space-y-4">
            <div className="font-bold text-xl">Байланыс ақпараттары</div>
            <div>
              <a href='tel:+77058663748' className="block text-blue-400 hover:text-blue-500">+7 (705) 866-37-48</a>
            </div>
          </div>
          <div className="space-y-4">
            <div className="font-bold text-xl">Әлеуметтік желілер</div>
            <div className="flex space-x-4">
              <a href='https://wa.me/+77058663748' className="hover:text-gray-300">
                {/* WhatsApp Icon */}
                {/*<WhatsAppOutlined style={{color: "white"}}/>*/}
              </a>
              {/* Telegram Icon */}
              <a href='https://t.me/username' className="hover:text-gray-300">
                {/* Inline SVG or use heroicons/react */}
                <TelegramSvg/>
              </a>
              <a className="hover:text-gray-300">
                {/* Instagram Icon */}
                {/*<InstagramOutlined style={{color: "white"}}/>*/}
              </a>
              <a className="hover:text-gray-300">
                {/* Facebook Icon */}
                {/*<FacebookOutlined style={{color: "white"}}/>*/}
              </a>
            </div>
          </div>
        </div>
        <div className="pt-10 mt-10 border-t border-gray-700">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div className="flex space-x-6">
              <Link to="/terms" className="text-blue-400 hover:text-blue-500">Условия оказания услуг</Link>
              <Link to="/privacy" className="text-blue-400 hover:text-blue-500">Политика конфиденциальности</Link>
            </div>
            <div className="text-sm">
              © 2022, Big Tabys - все права защищены.
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export {Footer}
