import React from 'react';
import {HomePage} from "./pages/home/HomePage";
import {Navigate, Route, Routes} from "react-router-dom";
import {AuthorizationPage} from "./pages/authorization/AuthorizationPage";
import Layout from "./app/layout/Layout";
import {PageNotFound} from "./pages/PageNotFound";
import {isUserValid} from "./pocketbase/pocketbase";
import {CoursePage} from "./pages/course/CoursePage";
import {CourseDetailPage} from "./pages/course-detail/CourseDetailPage";
import './App.css';

function App() {
  return (
    <Routes>
      <Route element={<Layout/>}>
        <Route index element={<HomePage/>}/>
        <Route path='login' element={<AuthorizationPage/>}/>
        <Route path='/course' element={isUserValid ? <CoursePage/> : <Navigate to='/login'/>}/>
        <Route path='*' element={<PageNotFound/>}/>
      </Route>
      {/*<Route path='/account' element={isUserValid ? <CoursePage/> : <Navigate to='/login'/>}>*/}
      {/*  <Route index element={<CoursePage/>}/>*/}
      {/*</Route>*/}
      <Route>
        <Route path='course/:id' element={<CourseDetailPage/>}/>
      </Route>
    </Routes>
  );
}

export default App;
