import style from './HomePage.module.css'
import {Link} from "react-router-dom";
import hero from '../../assets/hero.jpg'
import kaspi from '../../assets/shop.png'
import Accordion from "../../components/Accordion";

const items = [
  {
    key: '1',
    label: 'Курс қандай формата жүргізіледі?',
    children: 'Курс онлайн форматында жүргізіледі. Курсты сатып алғаннан кейін сізге дайын видео сабақтарға доступ беріледі. Сұрақтар туындаса - әрдайым жауап беретін чат поддержка болады.',
  },
  {
    key: '2',
    label: ' Мен Kaspi ден мүлдем хабарым жоқ, курсты толық игере аламын ба?',
    children: 'Видео сабақтар түсінікті және толық жазылған, кез-келген адам игеріп, Kaspi мен жұмысты бастап кете алады. Сонымен қатар, түсінбеген сұрақтарыңызға жауап беріп отыратын чат-поддержка болады.',
  },
  {
    key: '3',
    label: 'Қосымша табыс көзі ретінде Kaspi мен айналысуға болады ма?',
    children: 'Әрине айналысуға болады. Күнделікті 2-3 сағат бөлу арқылы қосымша табыс көзі ретінде жасасаңыз болады.',
  },
  {
    key: '4',
    label: 'Мен Kaspi мен айналысамын, осы курс арқылы табысты көтере аламын ба?',
    children: 'Курс сіз үшін пайдалы болады, себебі мен мұнда өзімнің 3 жыл бойы жинаған тәжірбиеммен және пайдалы инструменттермен бөлістім.',
  },
  {
    key: '5',
    label: 'Менде Kaspi магазин жоқ, оны ашуға қанша уақыт кетеді? ',
    children: 'Әдетте Kaspi магазинді ашу шамамен 2 айға дейінгі уақытты құрайды. Kaspi магазинді ашу туралы пайдалы кеңестер курста берілген.',
  },
  {
    key: '6',
    label: 'Курстағы сабақтарға доступ қанша уақытқа беріледі?',
    children: 'Сіз курсты сатып алған сәттен бастап 6 айға доступ беріледі.',
  }
]

const HomePage: React.FC = () => {
  return (
    <div className={style.home}>
      <section className={`${style.info__section}`}>
        <img className={style.bg__image} src={hero}/>
        <div className={`${style.hero__title}`}>
          <h1 className="text-3xl font-bold">
            Біздің білім алушыларымыз кім?
          </h1>
          <div className='grid lg:grid-cols-3 md:grid-cols-1 gap-4 items-start justify-between'>
            <div className="flex flex-col ">
              <div className="border p-3 bg-[#141414] border-[#353535] rounded-xl">
                Оқушылардың 70%
              </div>
              <h3 className="text-xl font-bold py-3">
                Новичоктар
              </h3>
              <h1 className="text-lg">
                Товарлық бизнес, маркетплейс және каспиден мүлдем хабары жоқтар
              </h1>
            </div>
            <div className="flex flex-col ">
              <div className="border p-3 bg-[#141414] border-[#353535] rounded-xl">
                Оқушылардың 20%
              </div>
              <h1 className="text-xl font-bold py-3">
                Каспи магазины бар
              </h1>
              <h3 className="text-lg">
                Білімін және табысын арттырғысы келетіндер
              </h3>
            </div>
            <div className="flex flex-col ">
              <div className="border p-3 bg-[#141414] border-[#353535] rounded-xl">
                Оқушылардың 10%
              </div>
              <h1 className="text-xl font-bold py-3">
                Кәсіпкелер
              </h1>
              <h3 className="text-lg">
                Каспи магазин арқылы саудасын улғайтқысы келетіндер
              </h3>
            </div>
          </div>
          <div className={`${style.hero__text}`}>
            <h1 className="text-4xl font-bold mb-3">Kaspi де табысқа шығудың ең оңай жолы.</h1>
            <h4 className="text-xl font-bold">Kaspi де сауда жасау бойынша #1 эксперт және практик. Осы салада 3 жыл
                                              тәжірбие.</h4>
          </div>
        </div>
      </section>

      <section className={style.section}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 bg-[#141414] border border-[#353535] rounded-xl">
            <div className="w-full">
              <div className="overflow-hidden rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <img className="w-full" src={kaspi} alt="Kaspi"/>
                <div className="p-4 space-y-4">
                  <h2 className="text-xl font-semibold">Курста сіз не үйренесіз :</h2>
                  <p className="text-secondary text-base text-[#7E7E7E]">
                    1. Каспи магазинді ашудың ең оңай жолы
                    <br/>
                    2. Продажаны 2-3 есе ұлғайтатын инструменттер
                    <br/>
                    3. Улкен табыс алып келетін товардарды іздеу жолдары, табу
                    <br/>
                    4. Товарлардың сатудың жолдары, оның ішінде бүкіл Қазақстанға сату
                    <br/>
                    5. Каспидегі продажаны ұлғайтатын фишкалар
                  </p>
                  <a href="#"
                     className="inline-block px-6 py-2 text-sm font-medium leading-6 text-center text-white uppercase transition bg-blue-500 rounded-full shadow ripple hover:shadow-lg focus:outline-none hover:bg-blue-600">
                    Толығырақ білу
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-center text-2xl font-bold mb-10">РАССРОЧКА</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Card 1 */}
            <div className="flex flex-col justify-between bg-[#141414] border border-[#353535] rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out p-4 space-y-4">
              <h3 className="text-lg font-semibold">standart</h3>
              <h4 className="text-3xl text-red-500">0-0-12</h4>
              <div className="text-secondary text-sm flex flex-col gap-6 justify-between">
                <p>
                  1. Онлайн курсқа толық доступ (0 ден бірінші пайдаға дейін үйренесің)<br/>
                  2. Сұрақтар туынадаса - әрдайым жауап беретін чат-поддержка<br/>
                  3. 2 рет менімен онлайн кездесу (маған сұрақтар қойып, кеңестер ала аласың)<br/>
                  4. Оқу ұзактығы - 1 ай<br/>
                  5. Формат - онлайн<br/>
                  6. Орындар саны - 50
                </p>
                <div className="">
                  <div className="text-2xl mb-2 font-bold underline">199.000 теңге</div>
                  <div className="text-base text-red-500">16.500 айына бөліп төлеу</div>
                </div>
              </div>
              <div className="pt-10 pb-3">
                <a
                  href="https://wa.me/+77058663748"
                  className="bg-[#141414] border border-[#353535] rounded-xl text-white px-6 py-2 text-lg font-medium hover:bg-blue-600 transition-colors duration-200"
                >
                  Сабақты сатып алу
                </a>
              </div>
            </div>
              {/* Card 2 */}
              <div className="flex flex-col justify-between bg-[#141414] border border-[#353535] rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out p-4 space-y-4">
                <h3 className="text-lg font-semibold">Наставникпен бірге</h3>
                <h4 className="text-3xl text-red-500">0-0-12</h4>
                <div className="text-secondary text-sm flex flex-col gap-6 justify-between">
                  <p>
                    1.Онлайн курсқа толық доступ (0 ден бірінші пайдаға дейін)<br/>
                    2.Наставникпен жұмыс<br/>
                    3.Дайын сататын товарлар<br/>
                    4.Группада 10 адамнан артық емес<br/>
                    5.Апта сайын наставникпен онлайн кездесу<br/>
                    6.Оқу барысында менімен 3 рет онлайн кездесу<br/>
                    7.Оқу ұзактығы - 1.5 ай<br/>
                    8.Формат - онлайн<br/>
                    9.Орындар саны - 30
                  </p>
                  <div className="">
                    <div className="text-2xl mb-2 font-bold underline">399.000 теңге</div>
                    <div className="text-base text-red-500">33.250 айына бөліп төлеу</div>
                  </div>
                </div>
                <div className="pt-10 pb-3">
                  <a
                    href="https://wa.me/+77058663748"
                    className="bg-[#141414] border border-[#353535] rounded-xl text-white px-6 py-2 text-lg font-medium hover:bg-blue-600 transition-colors duration-200"
                  >
                    Сабақты сатып алу
                  </a>
                </div>
              </div>
              {/* Card 3 */}
              <div className="flex flex-col justify-between bg-[#141414] border border-[#353535] rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out p-4 space-y-4">
                <h3 className="text-lg font-semibold">Наставникпен бірге + оффлайн оқу + поставщиктер</h3>
                <h4 className="text-3xl text-red-500">0-0-12</h4>
                <div className="text-secondary text-sm flex flex-col gap-6 justify-between">
                  <p>
                    1.Менің барлық поставщиктерімнің базасы (1000-нан астам товарлардың карточкасы)<br/>
                    2.Аптасына 1 рет менімен оффлайн немесе онлайн кездесу<br/>
                    3.Аптасына 1 рет наставникпен онлайн кездесу<br/>
                    ...<br/>
                    15.3 күн бойы 8 сағаттан оффлайн жұмыс
                  </p>
                  <div className="">
                    <div className="text-2xl mb-2 font-bold underline">1.000.000 теңге</div>
                    <div className="text-base text-red-500">83.000 айына бөліп төлеу</div>
                  </div>
                </div>
                <div className="pt-10 pb-3">
                  <a
                    href="https://wa.me/+77058663748"
                    className="bg-[#141414] border border-[#353535] rounded-xl text-white px-6 py-2 text-lg font-medium hover:bg-blue-600 transition-colors duration-200"
                  >
                    Сабақты сатып алу
                  </a>
                </div>
              </div>
          </div>
        </div>
      </section>

      <section className={`${style.courses} py-24`}>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
          <div className={style.home__section}>
            <div className={style.home__title}>
              Оқу платформасы
            </div>
            <div className={style.home__subtitle}>
              Біздің оқу жолымыз үшін біз жауаптымыз
            </div>
            <div className="flex gap-12 mb-10">
              <Link to='/course' className={style.home__link}>
                Курстар
              </Link>
              <Link to='/course' className={style.home__link}>
                Жеке кабинет
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className={style.section}>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
          <div className={style.course__title} style={{marginBottom: 30}}>
            Жиі қойылатын сұрақтар
          </div>
          {items.map(item => (
            <Accordion key={item.key} title={item.label}>
              {item.children}
            </Accordion>
          ))}
        </div>
      </section>

    </div>
  )
}

export {HomePage}
