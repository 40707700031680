
const PageNotFound: React.FC = () => {
  return (
    <div className="flex py-96 items-center justify-center w-full">
      <h1 className="text-3xl mb-0 text-center"> Бет табылмады </h1>
    </div>
  )
}

export {PageNotFound}
