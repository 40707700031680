import React, { useState, useRef, CSSProperties } from 'react';

const Accordion: React.FC<{ index?: number; title: string, children?: any }> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleRotate = (): string => {
    return isOpen ? '-rotate-180' : '';
  };

  const handleStyle = (): CSSProperties => {
    if (contentRef.current) {
      return isOpen ? { maxHeight: `${contentRef.current.scrollHeight}px` } : { maxHeight: '0px' };
    }
    return { maxHeight: '0px' }; // Return a default style if contentRef.current is null
  };

  return (
    <div className="relative transition-all duration-700 border bg-[#141414] border-[#353535] rounded-xl hover:shadow-2xl">
      <div
        onClick={handleToggle}
        className="w-full p-4 text-left cursor-pointer"
      >
        <div className="flex items-center justify-between">
          <span className="tracking-wide">{title}</span>
          <span className={`transition-transform duration-500 transform ${handleRotate()}`}>
            <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
            </svg>
          </span>
        </div>
      </div>
      <div ref={contentRef} style={handleStyle()} className="relative overflow-hidden transition-all duration-700">
        <div className="px-6 pb-4 text-gray-300">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
