import style from './Layout.module.css';
import {Outlet} from "react-router-dom";
import {Footer} from "./footer/Footer";
import { Header } from './header/Header';

const Layout: React.FC = () => {
  return (
    <>
      <div className={style.wrapper}>
        <header className={style.header}>
          <Header/>
        </header>
        <main className={style.main}>
          <Outlet/>
          {/*<FloatButton*/}
          {/*  className={style.float}*/}
          {/*  icon={<WhatsAppOutlined/>}*/}
          {/*  type="primary"*/}
          {/*  style={{right: 24}}*/}
          {/*  href='https://wa.me/+77058663748'*/}
          {/*/>*/}
        </main>
        <footer className={style.footer}>
          <Footer/>
        </footer>
      </div>
    </>
  );
}

export default Layout
