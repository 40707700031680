import {useEffect, useRef, useState} from "react";
import {getCourseView} from "../../pocketbase/pocketbase";
import {Link, useParams} from "react-router-dom";
import ReactHlsPlayer from "react-hls-player";

interface Course {
  id: string;
  name: string;
  expand?: {
    modules: Module[];
  };
}

interface Module {
  name: string;
  number: string;
  expand: {
    lessons: Lesson[];
  };
}

interface Lesson {
  name: string;
  number: string;
  video: string;
}

const CourseDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [course, setCourse] = useState<Course | null>(null);
  const [selectedLesson, setSelectedLesson] = useState<Lesson | null>(null);
  const [activeModuleIndex, setActiveModuleIndex] = useState<number | null>(null);
  const playerRef = useRef(null);

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const res = await getCourseView(id) as any;
        setCourse(res);
      } catch (error) {
        console.error("Failed to fetch course", error);
        // Обработка ошибок, например, показ сообщения пользователю
      }
    };
    fetchCourse();
  }, [id]);

  const handleModuleClick = (index: number) => {
    setActiveModuleIndex(activeModuleIndex === index ? null : index);
  };

  const renderModules = () => {
    if (!course?.expand?.modules) {
      return <p>No modules available.</p>;
    }

    return course.expand.modules.map((module, index) => (
      <div key={index} className="border-b border-gray-500">
        <div
          onClick={() => handleModuleClick(index)}
          className="cursor-pointer font-semibold p-2 bg-gray-200 rounded-md"
        >
          {module.name}
        </div>
        {activeModuleIndex === index && (
          <div className="pl-4 py-3 space-y-2">
            {module.expand.lessons.map((lesson, lessonIndex) => (
              <div key={lessonIndex} className="flex items-center">
                <div onClick={() => handleLessonSelect(lesson)} className="ml-2 cursor-pointer font-bold text-sm underline hover:no-underline">
                  {lesson.name}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    ));
  };
  const handleLessonSelect = (lesson: Lesson) => {
    setSelectedLesson(lesson);
  };
return(
  <div className="wrapper">
    <div className="content">
      <header className='header py-3'>
        <div className='header__wrapper container px-12'>
          <div className='header__logo'>
            <h1 className="text-3xl font-bold">Big Tabys</h1>
          </div>
          <div className='header__exit'>
            <Link to={'/course'}>Шығу</Link>
          </div>
        </div>
      </header>
      <div className='video'>
        {selectedLesson && (
          <ReactHlsPlayer
            playerRef={playerRef}
            className='video__player'
            controls
            src={selectedLesson.video}
          />
        )}
      </div>
      <div className='course px-12 py-6'>
        <h2 className="text-3xl font-bold">{selectedLesson?.name || ''}</h2>
      </div>
    </div>
    <aside className="sidebar">
      <div className="sidebar__wrapper">
        <div className="sidebar__header">Материалы курса</div>
        <div className="lessons">
          {renderModules()}
        </div>
      </div>
    </aside>
  </div>
)
};

export {CourseDetailPage};
