import PocketBase from 'pocketbase'

const url = 'https://admin.bigtabys.com/'
export const client = new PocketBase(url)
export const isUserValid = client.authStore.isValid;

// User
export async function login(username, password) {
  try {
    await client.collection("users").authWithPassword(username, password);
  } catch (error) {
    console.error("Ошибка авторизации", error);
    window.alert("Ошибка при попытке входа");
  }
}



export async function signout() {
  await client.authStore.clear();
  window.location.reload();
}

export async function getUserView() {
  return await client.collection("users").getOne(client.authStore.model.id);
}

export async function updateUser(userId, data) {
  try {
    const response = await client.collection("users").update(userId, data);
    return response;
  } catch (error) {
    console.error("Ошибка при обновлении данных пользователя:", error);
    throw error;
  }
}


// Courses
export async function getCourse() {
  return await client.collection("courses").getFullList({
    sort: '-created', expand: 'modules.lessons'
  });
}

// Course View
export async function getCourseView(id) {
  return await client.collection("courses").getOne(id, {
    expand: 'modules.lessons'
  });
}

// Module
export async function getModule(id) {
  return await client.collection("modules").getFullList({
    // expand: 'course.'
  });
}



// Module View
export async function getModuleView(id) {
  return await client.collection("modules").getOne(id);
}

// lessons
export async function getLessons() {
  return await client.collection("lessons").getFullList();
}

// lessons View
export async function getLessonsView(id) {
  return await client.collection("lessons").getOne('t8t13mztctbjs3z', {});
}

// Orders
export async function getOrders() {
  return await client.collection("orders").getList(1, 50, {
  });
}

// Order View
export async function getOrderView(course) {
  return await client.collection("orders").getOne({
    // expand: [{user: client.authStore.model.id}, {email: course}]
    // expand: "user",
    // fields: "user,expand.items.user",
  });
}
